import { Route } from "react-router-dom";
import loadable from "@loadable/component";
import Loading from "@/components/loading";

const FallbackOption = { fallback: <Loading /> };

const Index = loadable(() => import("@/pages/index"), FallbackOption);
const Main = loadable(() => import("@/pages/main"), FallbackOption);
const Nofount = loadable(() => import("@/pages/nofount"), FallbackOption);
const ScanCodeIndex = loadable(
  () => import("@/pages/ScanCode/index"),
  FallbackOption
);
const EndPage = loadable(
  () => import("@/pages/EndPage/index"),
  FallbackOption
);

const CodeIndex = loadable(
  () => import("@/pages/CodeList/index"),
  FallbackOption
);
const CodeList = loadable(
  () => import("@/pages/CodeList/list"),
  FallbackOption
);
const TryIndex = loadable(
  () => import("@/pages/tryGame/index"),
  FallbackOption
);
const DownloadApp = loadable(
  () => import("@/pages/downloadApp"),
  FallbackOption
);
const CreateProcessCard = loadable(
  () => import("@/pages/createProcessNew"),
  FallbackOption
);
const NewCreate = loadable(
  () => import("@/pages/createProcessNew/NewCreate"),
  FallbackOption
);
const TestQuestions = loadable(
  () => import("@/pages/testQuestions"),
  FallbackOption
);
const TestQuestionsDonePage = loadable(
  () => import("@/pages/testQuestions/donePage"),
  FallbackOption
);
const TestQuestionsList = loadable(
  () => import("@/pages/testQuestions/list"),
  FallbackOption
);
const TestQuestionsDetail = loadable(
  () => import("@/pages/testQuestions/detail"),
  FallbackOption
);
const ReportIndex = loadable(
  () => import("@/pages/report/index"),
  FallbackOption
);
const ReportList = loadable(
  () => import("@/pages/report/list"),
  FallbackOption
);
const CreateProcess = loadable(() => import("@/pages/createProcess/index"));
const Release = loadable(() => import("@/pages/createProcess/Release"));

export const routes = [
  {
    path: "/",
    name: "Main",
    exact: true,
    component: Main,
    title: "pixdaddy-元跃科技",
  },
  // {
  //   path: "/index",
  //   name: "Index",
  //   exact: true,
  //   component: Index,
  //   title: "pixdaddy",
  // },
  {
    path: "/downloadApp",
    name: "DownloadApp",
    exact: true,
    component: DownloadApp,
    title: "Pixdaddy",
  },
  {
    path: "/ScanCode",
    name: "ScanCodeIndex",
    exact: true,
    component: ScanCodeIndex,
    title: "拍照练习",
  },
  {
    path: "/EndPage",
    name: "EndPage",
    exact: true,
    component: EndPage,
    title: "通关",
  },
  {
    path: "/createProcess",
    name: "CreateProcess",
    exact: true,
    component: CreateProcessCard,
    title: "《单词100》创建练习卡",
  },
  {
    path: "/createProcessCard",
    name: "CreateProcessCard",
    exact: true,
    component: CreateProcessCard,
    title: "《单词100》创建练习卡",
  },
  {
    path: "/newCreate",
    name: "newCreate",
    exact: true,
    component: NewCreate,
    title: "《单词100》创建练习卡",
  },
  {
    path: "/testQuestions",
    name: "testQuestions",
    exact: true,
    component: TestQuestions,
    title: "《单词100》单词测评",
  },
  {
    path: "/testQuestions/done",
    name: "testQuestionsDonePage",
    exact: true,
    component: TestQuestionsDonePage,
    title: "《单词100》单词测评",
  },
  {
    path: "/testQuestions/list",
    name: "testQuestionsList",
    exact: true,
    component: TestQuestionsList,
    title: "单词主题",
  },
  {
    path: "/testQuestions/detail/:id",
    name: "testQuestionsDetail",
    exact: true,
    component: TestQuestionsDetail,
    title: "单词主题",
  },
  {
    path: "/release",
    name: "Release",
    exact: true,
    component: Release,
    title: "《单词100》创建成功",
  },
  {
    path: "/CodeIndex",
    name: "CodeIndex",
    exact: true,
    component: CodeIndex,
    title: "单词100",
  },
  {
    path: "/CodeList",
    name: "CodeList",
    exact: true,
    component: CodeList,
    title: "我的练习卡",
  },
  {
    path: "/TryIndex",
    name: "TryIndex",
    exact: true,
    component: TryIndex,
    title: "试玩体验",
  },
  {
    path: "/reportIndex",
    name: "reportIndex",
    exact: true,
    component: ReportIndex,
    title: "学习报告",
  },
  {
    path: "/reportIndex/:id",
    name: "ReportList",
    exact: true,
    component: ReportList,
    title: "学习报告",
  },
  {
    path: "*",
    name: "Nofount",
    title: "404",
    component: Nofount,
  },
];

// 路由权限控制
export function RouteWithSubRoutes (route: any) {
  // 登录认证
  document.title = route.title || "";
  return (
    <Route
      exact={route.exact || false}
      path={route.path}
      render={(props) => <route.component {...props} {...route} />}
    />
  );
}
